import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useRouter } from 'Utilities/useRouter';

interface Links {
    name: string
    value: string
};

interface Props {
    links: Array<Links>
    callback: Function
};

const VerticalNavLink = ({ links, callback }: Props) => {
    const router = useRouter()
    return (
        <>
            {links.map((link, index) => (
                <li key={`${link.value}_${index}`} className="em-u-clickable em-c-primary-nav__item">
                    <Link to={`${link.value}`} onClick={() => callback()} >
                        <NavLink className={`em-c-primary-nav__link ${router.location.pathname === link.value ? 'em-is-current' : ''}`}>{link.name}</NavLink>
                    </Link>
                </li>
            ))}
        </>
    );
};

const NavLink = styled.span`
  color: #ffffff;
`;

export default VerticalNavLink;