import attachmentIcon from "Assets/unity-1.6.0/images/bookmark-full.svg";
import { ShortDescription } from "Layouts/Section/SectionStyles";
import Icon from "SharedComponents/Icon";
import styled from "styled-components";
import { COLOR } from "Utilities/constants";

interface Documents {
  id: number;
  title: string;
  description: string;
  link: string;
}

const DocumentCard = ({ title, description, link }: Documents) => {
  return (
    <div className="em-l-grid__item">
      <DocumentCardStyle>
        <Content className="em-u-font-style-regular">
          <Title className="em-u-font-style-semibold em-u-font-size-large">
            {title}
            <img alt="icon" src={attachmentIcon} />
          </Title>
          <ShortDescription line={8}>
            {description}
          </ShortDescription>
        </Content>
        <ExternalLinkButton
          href={link}
          target={link.startsWith('/') ? '' : '_blank'}
          rel="noreferrer"
          className="em-u-text-align-right em-u-font-style-semibold"
        >
          {link.startsWith('/') ? 'Open page' : 'Open document'}
          <Icon name="caret-right" optionalClass="em-u-margin-left" />
        </ExternalLinkButton>
      </DocumentCardStyle>
    </div>)

}
const DocumentCardStyle = styled.div`
    box-shadow: 4px 4px 2px #0000001a;
    background-color: ${COLOR.WHITE};
    position: relative;
    margin-bottom: 2rem;
    height: 100%;
`;

const Content = styled.div`
    padding: 2rem;
    height: 18rem;
    overflow: hidden;
    white-space: pre-wrap;
    @media (max-width: 40em) {
        height: 20rem;
    }
`;

const Title = styled.div`
    color: ${COLOR.DEEP_BLUE};
    margin-bottom: 2rem;
    img {
        position: absolute;
        width: 2.5rem;
        top: -0.5rem;
        right: 1rem;
    }
`;

const ExternalLinkButton = styled.a`
    padding: 0.5rem;
    color: ${COLOR.WHITE};
    background: ${COLOR.PURPLE};
    font-size: 1.25rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export default DocumentCard;