import React from "react";
import styled from "styled-components";

interface Props {
    bgUrl: string;
    title: string;
    subtitle?: string;
};

const SubHeader: React.FC<Props> = ({
    bgUrl,
    title,
    subtitle,
}) => {

    return (
        <SubHeaderStyle bgUrl={bgUrl}>
            <Title>
                {title} |
                <SubTitle className="em-u-font-style-regular">
                    {" "}
                    {subtitle}
                </SubTitle>
            </Title>
        </SubHeaderStyle>
    );
};

interface StyledProps {
    bgUrl: string;
};

const SubHeaderStyle = styled.header<StyledProps>`
    background-image: url(${(props) => `${props.bgUrl}`});
    background-size: cover;
    min-height: 300px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 8rem;
    @media (max-width: 84em) {
        padding: 2rem;
    }
`;

const Title = styled.h2`
    color: white;
    margin: 2rem 0;
`;

const SubTitle = styled.span`
    font-size: 2rem;
    color: white;
    font-family: EMprintRegular;
    font-weight: normal;
`;

export default SubHeader;
