import styled from "styled-components";

const WelcomeSection = () => (
    <WelcomeContainer className="em-c-hero">
        <WelcomeBody className="em-c-hero__body">
            <Title className="em-u-font-style-semibold">
                Welcome to your<br />Open Source Office
            </Title>
            <SubTitle className="em-c-hero__desc em-u-font-size-med-3">
                We´re passionate about open source and excited about our mission of encouraging
                and enabling open source consumption and collaboration within organization
            </SubTitle>
        </WelcomeBody>
    </WelcomeContainer>
);

const WelcomeContainer = styled.div`
    background: none;
`;

const WelcomeBody = styled.div`
    background: none;
    padding: 4rem 10rem;
    @media (max-width: 84em) {
        padding: 4rem 5rem;
    }
    @media (max-width: 40em) {
        padding: 1rem 2rem;
    }
    @media (max-width: 37em) {
        padding: 1rem;
    }
`;

const Title = styled.h2`
    color: white;
    width: 100%;
    font-size: 3.75rem;
    font-family: EMprintSemibold;
    padding: 0 0 2rem 0;
    @media (max-width: 84em) {
        padding: 0 0 2rem 0;
    }
    @media (max-width: 40em) {
        padding: 1rem 2rem 0 2rem;
        font-size: 2rem;
    }
`;

const SubTitle = styled.div`
    color: white;
    font-family: EMprintRegular;
    @media (max-width: 84em) {
        padding: 0;
    }
    @media (max-width: 40em) {
        padding: 0 2rem;
    }
`;

export default WelcomeSection;