import { useState } from "react";
import { Link } from "react-router-dom";
import {
    HeaderBackGround,
    Container,
    Body,
    Title,
    CustomButton,
    NavPanel,
} from "./HeaderStyles";
import VerticalNavLink from "./VerticalNavLink";
import Icon from "SharedComponents/Icon";
import CodeContributionForm from "Pages/Homepage/Sections/CodeContributionSection/Components/CodeContributionForm";

const Header = () => {
    const [isContributionFormShowing, setIsContributionFormShowing] = useState(false);
    const [isMenuActive, setIsMenuActive] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const scrollTo = (elementId: string) => () => {
        document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
    };
    const isLocationHome = () => {
        const href = window.location.href;
        return href.substr(href.lastIndexOf('/') + 1) === "";
    };
    const onClick = () => {
        scrollToTop();
        setIsMenuActive(false);
    };
    const headerLinks = [
        { name: "Home", value: "/" },
        { name: "Documents", value: "/documents" },
    ];

    return (
        <div className="header">
            <HeaderBackGround
                isHomePage={isLocationHome()}
                zindex={isMenuActive ? 20 : 5}
                role="banner"
            />
            <Container id="header-navbar">
                <div className="em-l-container">
                    <Body className="em-c-header__body">
                        <div
                            className="em-c-header__title-container margin"
                            onClick={scrollToTop}
                        >
                            <Title className="em-c-header__title">
                                <Link to="/">Open Source Office</Link>
                            </Title>
                        </div>
                        <button
                            className="em-c-btn em-c-btn--small em-c-btn--inverted em-c-header__nav-btn"
                            onClick={() => setIsMenuActive(!isMenuActive)}
                        >
                            <div className="em-c-btn__inner">
                                {!isMenuActive ? (
                                    <Icon name="hamburger" optionalClass="em-c-icon" />
                                ) : (
                                    <Icon name="x-filled" optionalClass="em-c-icon" />
                                )}
                                <span className="em-c-btn__text em-js-btn-label">
                                    {isMenuActive ? "Close" : "Menu"}
                                </span>
                            </div>
                        </button>
                        <NavPanel
                            className={`em-c-header__nav-container em-js-nav-panel ${isMenuActive ? "em-is-active" : ""
                                }`}
                        >
                            <nav
                                id="nav"
                                className="em-c-primary-nav"
                                role="navigation"
                            >
                                <ul className="em-c-primary-nav__list">
                                    {!isLocationHome() ? (
                                        <VerticalNavLink
                                            links={[{ name: "Home", value: "/" }]}
                                            callback={onClick}
                                        />
                                    ) : (
                                        <>
                                            <VerticalNavLink
                                                links={headerLinks}
                                                callback={onClick}
                                            />
                                            <li className="em-u-clickable em-c-primary-nav__item">
                                                <span className="em-c-primary-nav__link" onClick={scrollTo("faq")}>FAQ</span>
                                            </li>
                                            <li className="em-u-clickable em-c-primary-nav__item">
                                                <span className="em-c-primary-nav__link" onClick={scrollTo("team")}>Contact us</span>
                                            </li>
                                        </>
                                    )
                                    }
                                    <li
                                        className="em-c-primary-nav__item "
                                        onClick={() =>
                                            setIsContributionFormShowing(true)
                                        }
                                    >
                                        <CustomButton className="em-c-btn" onClick={() => setIsContributionFormShowing(true)}>
                                            Code contribution request
                                        </CustomButton>
                                    </li>
                                </ul>
                            </nav>
                        </NavPanel>
                    </Body>
                </div>
            </Container>
            {isContributionFormShowing && (
                <CodeContributionForm closeCallback={() => setIsContributionFormShowing(false)} />
            )}
        </div>
    );
};

export default Header;