import { authProvider } from "./auth";

interface IRequestData {
    requestId: number,
    primaryContact: string,
    businessLine: string,
    country: string,
    projectLink: string,
    description: string
}
export const submitRequest = async (data: IRequestData) => {
    const tokenResponse = await authProvider.getAccessToken();
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${tokenResponse.idToken}`,
        },
        body: JSON.stringify(data),
    };
    let response = await fetch(`${process.env.REACT_APP_LOGIC_APP_URL}`, options);
    return response;
}