export const listdocs = [
    {
        id: 1,
        title: "External code contributions",
        //description: "- Management approval \n- Desirability of release \n- Active project \n- The contribution process for your department",
        description: "Learn about how to contribute code to external repositories.",
        link: "https://mysite.na.xom.com/personal/SA_FAABREU/Shared%20Documents/External%20Contribution%20Process%20overview.docx"
    },
    {
        id: 2, 
        title: "Using Open Source Components", 
        description: "Read these best practices for using open source components in your developments.",
        link: "https://ishareteam2.na.xom.com/sites/DevSvcs/OSO%20Documents/Consuming%20Open%20Source%20Components.docx"
    },    
    {
        id: 3,
        title: "Law Library",
        description: "Link to Law library containing list of open source licenses endorsed for use and supporting documentation.",
        //link: "https://ishareteam1.na.xom.com/sites/ITLaw/BLE%20Library/Forms/AllItems.aspx"
        link: "/lawlibrary"
    },
    { 
        id: 4, 
        title: "Overview", 
        description: "Explore main topics about open source.", 
        link: "https://ishareteam2.na.xom.com/sites/DevSvcs/OSO%20Documents/Training%20Mateiral/OpenSourceOffice_Training.pptx?Web=1" 
    },
];
