import Icon from "SharedComponents/Icon";
import styled from "styled-components";
import { COLOR } from "Utilities/constants";
import defaultAvatar from "Assets/unity-1.6.0/images/default-avatar.svg";
import { useState } from "react";

type TeamMember = {
    name: string;
    email: string;
    role: string;
    lanid: string;
    pic: string;
    location: string;
    country: string;
}

const ContactCard = ({ name, email, role, lanid, pic, location, country }: TeamMember) => {
    const [avatar, setAvatar] = useState(pic);
    const mySite = "https://mysite.na.xom.com/Person.aspx?accountname=";

    return (
        <ContactCardGrid className="em-l-grid__item">
            <ContactCardStyle>
                <div className="em-c-card__body">
                    <div className="em-c-media-block em-c-media-block--small">
                        <AvatarTopFrame>
                            <AvatarBottomFrame>
                                <Avatar
                                    src={avatar ? avatar : defaultAvatar}
                                    onError={() => setAvatar(defaultAvatar)}
                                    alt="Person avatar"
                                    className="em-c-media-block__img em-c-avatar photo" />
                            </AvatarBottomFrame>
                        </AvatarTopFrame>
                        <div className="em-c-media-block__body">
                            <h2 className="em-c-media-block__headline fn">
                                {name}
                            </h2>
                            <p className="em-c-media-block__desc"><span className='em-u-font-size-med'>{role}</span>
                                <br /><span className='em-u-font-size-med'>{location}, {country}</span></p>
                            <div className="em-c-status em-c-status--inactive">

                            </div>
                        </div>
                    </div>
                </div>
                <ContactCardFooter>
                    <ul className="em-c-link-list em-c-link-list--small em-c-link-list--has-icons">
                        <li className="em-c-link-list__item">
                            <a href={`mailto:` + email} className="em-c-link-list__link email">
                                <Icon name="envelope-closed" optionalClass="em-c-link-list__icon" />
                                <span className="em-c-link-list__text"> {email}</span>
                            </a>
                        </li>
                        <li className="em-c-link-list__item">
                            <a href={mySite + lanid} className="em-c-link-list__link url" rel="noopener noreferrer" target="_blank">
                                <Icon name="link" optionalClass="em-c-link-list__icon" />
                                <span className="em-c-link-list__text">MySite</span>
                            </a>
                        </li>
                        <li className="em-c-link-list__item">
                            <a href={`sip:` + email} className="em-c-link-list__link ">
                                <Icon name="comment" optionalClass="em-c-link-list__icon" />
                                <span className="em-c-link-list__text">Send IM</span>
                            </a>
                        </li>
                    </ul>
                </ContactCardFooter>
            </ContactCardStyle>
            <CardDecoration />
        </ContactCardGrid>
    )
}

const ContactCardGrid = styled.div`
    color: ${COLOR.GRAY};
`;

const ContactCardStyle = styled.div`
    box-shadow: 4px 4px 2px #0000001a;
    background-color: ${COLOR.WHITE};
    border: 1px solid #e5e5e5;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem 1rem;
    max-width: 26rem;
    font-family: EMPrintRegular;
`;

const AvatarTopFrame = styled.div`
    box-shadow: 2px 2px 0 #7A4183;
    border-radius: 50%;
    margin-right: 15px;
    max-height: 6.7rem;
`;

const AvatarBottomFrame = styled.div`
    box-shadow: -2px -2px 0 #7A4183;
    border-radius: 50%;
`;

const ContactCardFooter = styled.div`
    a {
        color: ${COLOR.GRAY};
        font-size: 0.875rem;
    }
    padding: 1.5rem 1rem 0 1rem;
    word-break: break-word;
`;

const CardDecoration = styled.div`
    background-color: ${COLOR.GRAY};
    height: 0.7rem;
    box-shadow: 4px 4px 2px #0000001a;
    max-width: 26rem;
`;

const Avatar = styled.img`
    object-fit: cover;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: 3px 3px 0px 3px;
`;

export default ContactCard;