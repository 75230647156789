import { authProvider } from "Services/auth";

export const getLawFiles = async (): Promise<any[]> => {
  const tokenResponse = await authProvider.getAccessToken({
    scopes: ['https://storage.azure.com/user_impersonation'],
  });
  return fetch(`${process.env.BLOB_STORAGE_LOCATION}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${tokenResponse.accessToken}`,
        'x-ms-version': '2019-07-07'
      },
    }
  ).then((response) => {
    return response.json();
  },
  );
}