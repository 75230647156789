import styled from "styled-components";
import backgroundImg from "Assets/unity-1.6.0/images/welcome-background.png";
import documentsbackgroundImg from "Assets/unity-1.6.0/images/pages-background.png";

interface HeaderProps {
    zindex?: number;
    isHomePage?: boolean;
}

export const HeaderBackGround = styled.header<HeaderProps>`
    position: absolute;
    margin-bottom: 2rem;
    z-index: ${(props) => props.zindex};
    width: 100%;
    min-height: ${props => props.isHomePage ? "45rem" : "25rem"};
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url(${props => props.isHomePage ? backgroundImg : documentsbackgroundImg});
    @media (min-width: 47em) {
        z-index: 5;
    }
    @media (max-width: 47rem) {
        min-height: 57.5rem;
    }
`;

export const Container = styled.div`
    position: fixed;
    color: #f5f5f5;
    width: 100%;
    background-color: #011022CC;
    height: 6rem;
    z-index: 100;
    @media (max-width: 47em) {
        padding: 0.5rem 0;
        height: 5rem;
    }
    @media (min-width: 47em) and (max-width: 62em) {
        height: 7rem;
        .em-c-primary-nav__list{
            top: 0;
            font-size: 0.85rem;
        }
    }
`;

export const Body = styled.div`
    padding: 0 0;
    @media (max-width: 120em) {
        padding: 0 0;
    }
    @media (max-width: 84em) {
        padding: 0 2rem;
    }
    @media (max-width: 64em) {
        padding: 1rem 2rem;
    }
    @media (max-width: 36em) {
        padding: 0.5rem 1.1rem;
    }
    
    button {
        .em-c-btn__inner {
            min-height: 20px;
        }
        @media (max-width: 64em) {
           height: 2rem;
        }
    }
`;
export const Title = styled.h2`
    font-size: 1.5rem;
    font-family: EMprintSemibold;
    margin-bottom: 0.25rem;
    a {
        color: #ffffff;
    }
    @media (max-width: 36rem) {
        font-size: 1.35rem;
    }
`;
export const NavPanel = styled.div`
    ul {
        margin-top: 0.4rem;
    }
    @media (max-width: 49rem) {
        .em-c-primary-nav__item {
            margin-right: 0.5rem;
        }
    }
    @media (max-width: 65rem) {
        .em-c-primary-nav__item {
            margin-right: 0;
        }
    }
`;

export const CustomButton = styled.button`
    background: none;
    color: #ffffff;
    margin: 0.5rem 1rem;
    max-width: 14rem;
    padding: 0 1rem;
    &:hover {
        font-family: EMprintSemiBold;
        background: #ffffff;
    }
`;