import Section from "Layouts/Section";
import { COLOR } from "Utilities/constants";
import { OtherButton } from "Layouts/Section/SectionStyles";
import { listfaqs } from "Utilities/listfaqs";
import ExpandableSection from "./Components/ExpandableSection";
import styled from "styled-components";
import Icon from "SharedComponents/Icon";

const FaqSection = () => {
    return (
        <Section
            backgroundColor={COLOR.WHITE}
            sectionId="faq"
            title="FAQ"
            titleColor={COLOR.BLACK}
            subtitle="Ask us to get more information"
        >
            <IconContainer>
                <Icon name="comment-conversation" color={COLOR.PURPLE} />
            </IconContainer>
            <QuestionsContainer>
                {listfaqs.map((faq) => (
                    <ExpandableSection key={faq.id} {...faq} />
                ))}
            </QuestionsContainer>
            <OtherButton>
                <button className="em-c-btn em-u-font-style-semibold">
                    <a href="mailto:opensource@exxonmobil.com">Submit my question</a>
                </button>
            </OtherButton>
        </Section>
    );
};

const IconContainer = styled.div`
    position: relative;
    svg {
        position: absolute;
        top: -6rem;
        right: 0;
        width: 8rem;
        height: 8rem;
    }
    @media (max-width: 50em) {
        display: none;
    }
`;

const QuestionsContainer = styled.div`
    padding: 2rem 0;
    p {
        font-size: 1.125rem;
        font-family: EMPrintRegular;
        white-space: pre-wrap;
    }
    @media (max-width: 50em) {
        padding-top: 0;
    }
`;

export default FaqSection;