import styled from "styled-components";
import { COLOR } from "Utilities/constants";

interface Props {
    color?: string;
    line?: number;
}

export const SectionTitle = styled.h2<Props>`
    color: ${(props) => props.color || COLOR.WHITE};
    font-size: 2rem;
    margin-bottom: 3rem;
`;

export const SectionSubTitle = styled.span`
    font-size: 1.5rem;
    font-family: EMprintRegular;
    font-weight: normal;
`;

export const SectionContainer = styled.div<Props>`
    padding: 4rem 8rem 3.5rem;
    background: ${(props) => props.color || COLOR.WHITE};
    scroll-margin-top: 6rem;
    @media (max-width: 84em) {
        padding: 3rem;
    }
    @media (max-width: 40em) {
        padding: 2rem;
    }
`;

export const SeeAllButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .em-c-btn {
        color: ${COLOR.PURPLE};
        border: none;
    }
`;

export const OtherButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .em-c-btn {
        a{
            color: ${COLOR.WHITE};
            font-family: EMPrintSemibold;
        }
        background-color: ${COLOR.PURPLE};
        border: none;
    }
    .em-c-btn:hover {
        cursor: pointer;
        opacity: 0.9;
    } 
`;

export const ShortDescription = styled.div<Props>`
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.line};
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.125rem;
    @media (max-width: 25em) {
        -webkit-line-clamp: ${(props) => props.line ? props.line - 3 : 3};
    }
`;
