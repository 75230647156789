import Section from "Layouts/Section";
import { COLOR } from "Utilities/constants";
import { listteam } from "Utilities/listteam";
import ContactCard from "./Components/ContactCard";

const TeamSection = () => {
    return (
        <Section
            backgroundColor={COLOR.PURPLE}
            sectionId="team"
            title="Open Source Office Team"
        >
            <div className="em-l-grid em-l-grid--3up em-u-margin-bottom-double">
                {listteam.map((teamMember) => (
                    <ContactCard
                        key={teamMember.lanid}
                        name={teamMember.name}
                        email={teamMember.email}
                        role={teamMember.role}
                        lanid={teamMember.lanid}
                        pic={teamMember.pic}
                        location={teamMember.location}
                        country={teamMember.country} />
                ))}
            </div>
        </Section>
    );
};

export default TeamSection;