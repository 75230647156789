export interface IData {
    id: string,
    name: string,
    date: string,
    country: string,
}

interface IState {
    currentData: Array<IData>,
    isDataLoading: boolean
}
const initState:IState = {
    currentData: [],
    isDataLoading: true,
}

export default (state:IState = initState, action:any) => {

    switch (action.type) {
        case 'IS_DATA_LOADING_UPDATE':
            return {...state, isDataLoading: action.payload.isDataLoading}
        case 'CURRENT_DATA_UPDATE':
            return {...state, currentData: action.payload.currentData}
        default:
            return state
    }
}