import { useState } from "react";
import { IUser } from "Services/GraphServices/getUsers";
import { InputStyles } from "../InputField";
import { getUsers } from "Services/GraphServices/getUsers";
import styled from "styled-components";
import LoadingIcon from "../LoadingIcon";

interface Props {
    onChange: (newValue: string) => void;
    label?: string;
    placeholder?: string;
    optionalClass?: string;
}

const PeoplePicker = ({ onChange, label, placeholder, optionalClass }: Props) => {
    const [searchValue, setSearchValue] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<IUser[]>();

    const onSearchValue = (value: string) => {
        setSuggestions([]);
        setSearchValue(value);
        setIsLoading(true);
        if (value.length >= 3) {
            getUsersList(searchValue);
        }
    }

    const getUsersList = async (value: string) => {
        try {
            const usersList = await getUsers(value);
            setSuggestions(usersList);
            setIsActive(true);
            setIsLoading(false);
            return usersList;
        } catch (error) {
            throw new Error();
        }
    };

    const onSelectValue = (value: string) => {
        setSearchValue(value);
        onChange(value);
        setIsActive(false);
        setIsLoading(false);
    }

    return (
        <InputStyles className={`${optionalClass}`}>
            <div className="em-c-field em-js-typeahead">
                {label ? (
                    <label className="em-c-field__label em-u-font-style-regular">
                        {label}
                    </label>
                ) : null}
                <div className="em-c-field__body">
                    <input
                        type="serach"
                        id="seach"
                        className="em-c-input em-js-typeahead"
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={(e) => onSearchValue(e.target.value)}
                    />
                    {(isLoading && searchValue.length >= 3) && <LoadingIcon />}
                    {(isActive && searchValue.length >= 3 && suggestions !== undefined && suggestions.length > 0) &&
                        <SuggestionsContainer>
                            <ul className="em-c-typeahead-list">
                                {
                                    suggestions.map((value) => (
                                        <List key={value.id} onClick={() => onSelectValue(value.displayName)}>
                                            <div className="em-c-typeahead__suggestion">{value.displayName}</div>
                                            <span className="em-c-typeahead__suggestion">{value.mail}</span>
                                        </List>
                                    ))
                                }
                            </ul>
                        </SuggestionsContainer>
                    }
                    {(suggestions !== undefined && suggestions.length === 0 && !isLoading) &&
                        <SuggestionsContainer className="em-c-typeahead-list__item">We could not find the user in the system.</SuggestionsContainer>
                    }
                </div>
            </div>
        </InputStyles>
    );
}

const SuggestionsContainer = styled.div`
    position: absolute;
    z-index: 6;
    background: white;
    max-height: 13rem;
    overflow: auto;
    overflow-x: hidden;
    width: -webkit-fill-available;
    border: 1px solid #cccccd;
`;

const List = styled.li`
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0.5rem;
    cursor: pointer;
    z-index: 10000 !important;
    span {
        font-family: EMPrintLight;
        padding-top: 0.25rem;
    }
    :hover {
        background: lightgray;
    }
`;

export default PeoplePicker;