import { useState } from "react";
import Button from "SharedComponents/Button";
import InputField from "SharedComponents/InputField";
import styled from "styled-components";
import { COLOR } from "Utilities/constants";
import PeoplePicker from "SharedComponents/PeoplePicker"
import { submitRequest } from "Services/submitRequest";
import LoadingIndicator from "SharedComponents/LoadingIndicator";
import PopUpMessage from "SharedComponents/PopUpMessage";

interface Props {
  closeCallback: Function;
}

const CodeContributionForm = ({ closeCallback }: Props) => {
  const [primaryContact, setPrimaryContact] = useState("");
  const [businessLine, setBusinessLine] = useState("");
  const [country, setCountry] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(0);

  const formState = [
    primaryContact,
    businessLine,
    country,
    projectLink,
    description
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const requestData = {
      requestId: new Date().getTime(),
      primaryContact: primaryContact,
      businessLine: businessLine,
      country: country,
      projectLink: projectLink,
      description: description
    }
    submitRequest(requestData).then((res) => {
      setIsLoading(false);
      setStatus(res.status);
    });
  }

  return (
    <Overlay>
      {(!isLoading && !status) ? (
        <Container>
          <Header>
            <span className="em-u-font-style-regular em-u-font-size-large-3">
              Code contribution request
            </span>
            <span className="em-u-font-size-med-3 em-u-font-style-regular em-u-padding-top">
              <u>
                Please review the external contribution process before submitting
                your request.
              </u>
            </span>
          </Header>
          <Body>
            <div className="em-l-grid em-l-grid--2up">
              <PeoplePicker
                label="Primary Contact"
                placeholder="Select a person"
                onChange={setPrimaryContact}
                optionalClass="em-l-grid__item"
              ></PeoplePicker>
              <InputField
                value={businessLine}
                onChange={setBusinessLine}
                optionalClass="em-l-grid__item"
                label="Business Line"
                placeholder="Provide business line"
              />
              <InputField
                value={country}
                onChange={setCountry}
                optionalClass="em-l-grid__item"
                label="Country"
                placeholder="Provide country"
              />
              <InputField
                value={projectLink}
                onChange={setProjectLink}
                optionalClass="em-l-grid__item"
                label="Link to project you want to contribute to"
                placeholder="Provide project´s link"
              />
            </div>
            <label className="em-c-field__label body-text em-u-font-size-med em-u-font-style-regular em-u-padding-top">
              Please provide a brief description of your contribution:
            </label>
            <textarea
              value={description}
              rows={6}
              placeholder="Provide more information here"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Body>
          <div className="em-u-padding-top-double">
            <Button
              optionalClass="em-c-btn--primary"
              name="Submit"
              callback={onSubmit}
              disabled={!formState.every((item) => item.length > 0)}
            />
            <Button
              optionalClass="em-u-margin-left em-c-btn em-c-btn--inverted"
              name="Cancel"
              callback={closeCallback}
            />
          </div>
        </Container>
      ) : (
        isLoading && (<LoadingIndicator />)
      )}
      {status === 200 && <PopUpMessage message="We will process your request and get in touch with you." header="Your request has been submitted" closeCallback={closeCallback}></PopUpMessage>}
      {status !== 200 && status && <PopUpMessage message="Your Request failed to be recorded, try again later or contact OSO Team directly." header="Sorry, request failed" closeCallback={closeCallback}></PopUpMessage>}
    </Overlay>
  );
};

const Overlay = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  text-align: left;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000 !important;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

const Container = styled.div`
  background: ${COLOR.GRAY};
  color: ${COLOR.WHITE};
  display: flex;
  width: 80rem;
  flex-direction: column;
  padding: 4rem 6rem;
  margin: auto 2rem;
  @media (max-width: 60rem) {
    padding: 3rem 4rem;
  }
  @media (max-width: 36rem) {
    width: 28rem;
    padding: 2rem 2.5rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 2rem;
`;

const Body = styled.div`
  background: ${COLOR.GRAY};
  color: ${COLOR.WHITE};
  label {
    color: ${COLOR.WHITE};
  }
  padding: 1.5rem 0;
`;

export default CodeContributionForm;

