import styled from "styled-components";
import loader from "Assets/unity-1.6.0/images/icon-spinner.svg";

const LoadingIndicator = () => {
    return (
        <LoaderStyle className="em-c-loader">
            <img src={loader} alt="Loading" />
        </LoaderStyle>
    );
};

const LoaderStyle = styled.div`
    height: 5rem;
    width: 5rem;
    margin: calc(30vh - 1.5rem) auto calc(28vh - 1.5rem) auto;
    position: relative;

    &:after {
        content: "Loading";
        font-family: EMPrintSemibold;
        font-size: 0.9rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;
        position: absolute;
        bottom: -1.6rem;
        left: 0;
        color: white;
    }
`;

export default LoadingIndicator;