export const listteam = [
        // {
        //     name: 'Abreu, Francis B',
        //     email: 'francis.b.abreu@exxonmobil.com',
        //     role: 'Product Owner',
        //     lanid: 'SA\\FAABREU',
        //     pic: 'https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/sa_faabreu_LThumb.jpg',
        //     location: 'Curitiba',
        //     country: 'BR',
        // },
        // {
        //     name: 'Filho, Amauri B',
        //     email: 'amauri.b.filho@exxonmobil.com',
        //     role: 'Advisor',
        //     lanid: 'SA\\ABFILHO',
        //     pic: 'https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/sa_abfilho_LThumb.jpg',
        //     location: 'Curitiba',
        //     country: 'BR',
        // },
        {
            name: 'Fontenele, Filipe',
            email: 'filipe.fontenele@exxonmobil.com',
            role: 'Advisor',
            lanid: 'SA\\FFONTEN',
            pic: 'https://mysite.na.xom.com/User%20Photos/Profile%20Pictures/sa_ffonten_LThumb.jpg?t=63671589982',
            location: 'Curitiba',
            country: 'BR',
        }        
    ]