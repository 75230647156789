import * as msal from "@azure/msal-browser"
import { AnyAction, Store } from 'redux'
import { AuthenticationState, LoginType } from './enums'
import { IAccountInfo } from './interfaces'
import { AuthenticationActionCreators } from './AuthenticationActionCreators';

type AuthenticationStateHandler = (state: AuthenticationState) => void
// type ErrorHandler = (error: msal.AuthError | null) => void
// type AccountInfoHandlers = (accountInfo: IAccountInfo | null) => void

export class MsalProvider extends msal.PublicClientApplication {
    public authenticationState!: AuthenticationState
    public UserAgentApplication!: msal.PublicClientApplication
   
    // private _onAccountInfoHandlers = new Set<AccountInfoHandlers>()
    private _onAuthenticationStateHandlers = new Set<AuthenticationStateHandler>()

    protected _parameters: any
    protected _options: any
    protected _config: any
    protected _accountInfo!: IAccountInfo
    protected _error!: msal.AuthError
    protected _username!: string
    protected _token!: string
    protected _loginState = false
    protected _reduxStore!: Store

    private _actionQueue: AnyAction[] = []
  
    constructor(
        config: any,
        parameters: any,
        options: any = {
            loginType: LoginType.Redirect,
            tokenRefreshUri: window.location.origin,
        }
    ) {
        super(config)
        
        // localStorage.clear()
        this.setAuthenticationState(AuthenticationState.Unauthenticated)
        this.setAuthenticationParameters(parameters)
        this.setProviderOptions(options)
    }

    public getLoginState = (): any => {
        return this._loginState
    }

    public setLoginState = (value :boolean): any => {
        this._loginState = value
    }

    public getAuthenticationParameters = (): any => {
        return { ...this._parameters }
    }
    
    public setAuthenticationParameters = (parameters: any): void => {
        this._parameters = { ...parameters }
    }
    
    public getError = () => {
        return this._error ? { ...this._error } : null
    }

    public getProviderOptions = (): any => {
        return { ...this._options }
    }

    public setProviderOptions = (options: any) => {
        this._options = { ...options }
        // if (options.loginType === LoginType.Redirect) {
        //   this.handleRedirectCallback(this.authenticationRedirectCallback)
        // }
    }

    public setConfig = (config: any) => {
        this._config = { ...config }
        // if (options.loginType === LoginType.Redirect) {
        //   this.handleRedirectCallback(this.authenticationRedirectCallback)
        // }
    }

    private setAuthenticationState = (state: AuthenticationState): AuthenticationState => {
        if (this.authenticationState !== state) {
          this.authenticationState = state
    
          this._onAuthenticationStateHandlers.forEach(listener => listener(state))
        }
    
        return this.authenticationState
    }

    public registerAuthenticationStateHandler = (listener: AuthenticationStateHandler) => {
        this._onAuthenticationStateHandlers.add(listener)
        listener(this.authenticationState)
    }
    
    public unregisterAuthenticationStateHandler = (listener: AuthenticationStateHandler) => {
        this._onAuthenticationStateHandlers.delete(listener)
    }

    public registerReduxStore = (store: Store): void => {
        this._reduxStore = store
        while (this._actionQueue.length) {
            const action = this._actionQueue.shift()
            if (action) {
                this.dispatchAction(action)
            }
        }
    }

    private dispatchAction = (action: AnyAction): void => {
        if (this._reduxStore) {
            this._reduxStore.dispatch(action)
        } else {
            this._actionQueue.push(action)
        }
    }

    public login = async () => {
        await this.handleRedirectPromise().then(
            this.handleResponse   //assign username or get parameter for new request token
        ).catch((error:any) => {
            console.error(error)
        })
        this.setAuthenticationState(AuthenticationState.InProgress)
        await this.getAccessToken()
    }
    
    public getAccessToken = async (parameters?: any): Promise<any> => {
        let request = parameters || this.getAuthenticationParameters()

        if(this._username) {
            request.account = await this.getAccountByUsername(this._username)
        }
        await this.acquireTokenSilent(request).then((res:any) => {
            this._token = res
            this.dispatchAction(AuthenticationActionCreators.acquireAccessTokenSuccess(res))
            this.setAuthenticationState(AuthenticationState.Authenticated)
        }).catch((error:any) => {
            console.warn("silent token acquisition fails. acquiring token using redirect")
            if (error instanceof msal.InteractionRequiredAuthError) {
                return this.acquireTokenRedirect(request)
            } else {
                console.warn(error)
            }
        })
        return this._token
    }

    public handleResponse = async(resp:any) => {
        if (resp !== null) {
            this._username = resp.account.username
            this._token = resp.accessToken
        } else {
            this.loadPage()
        }
    }

    public loadPage = () => {
        const currentAccounts = this.getAllAccounts()
        if (currentAccounts === null || currentAccounts.length === 0) {
            this.processLogin(this.getAuthenticationParameters())	
            return
        } else if (currentAccounts.length > 1) {
            console.warn("Multiple accounts detected.")
            this._username = currentAccounts[0].username
        } else if (currentAccounts.length === 1) {
            this._username = currentAccounts[0].username
        }
    }

    private processLogin = async (parameters:any) => {
        if (this.getError()) {
            this.setAuthenticationState(AuthenticationState.Unauthenticated)
            console.error('error', this.getError())
        } else {
            await this.loginRedirect(parameters).catch((error:any) => {
                console.error('login error', error)
            })	
        } 
    }
}
  