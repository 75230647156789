import React, { useState, useEffect } from "react";
//import LoadingIcon from '../LoadingIcon';

interface IProps {
    name: string;
    optionalClass?: string;
    callback: Function;
    loading?: boolean;
    disabled?: boolean;
}

export const Button: React.FC<IProps> = ({
    name,
    optionalClass,
    callback,
    loading,
    disabled
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const showLoading = () => {
        setIsLoading(true);
        callback(name);
    };

    useEffect(() => {
        if (loading !== undefined) {
            setIsLoading(loading);
        }
    }, [loading]);

    return (
        <button
            className={`${optionalClass} em-c-btn ${disabled ? 'em-c-btn--disabled' : ''}`}
            onClick={() => {
                (loading !== undefined ? loading : false)
                    ? showLoading()
                    : callback(name);
            }}
        >
            {isLoading ? <div>Loading...</div>: name}
        </button>
    );
};

export default Button;
