import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import styled from "styled-components";
import Homepage from "../Pages/Homepage/Homepage";
import Footer from "../SharedComponents/Footer/Footer";
import Header from "../SharedComponents/Header/Header";
import DocumentsPage from "./DocumentsPage";
import LawLibraryPage from "./LawLibraryPage";


const App = () => (
  <AppStyled>
    <div className="app__content">
      <Router>
        <LastLocationProvider>
          <Route path="/" component={Header} />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/documents" component={DocumentsPage} />
            <Route path="/lawlibrary" component={LawLibraryPage} />
          </Switch>
          <Footer />
        </LastLocationProvider>
      </Router>
    </div>
  </AppStyled>
);

export const AppStyled = styled.div`
    margin: 0;
    padding: 0;
    background-color: white;
    -webkit-font-smoothing: antialiased;
    display: flex;
    min-height: 100vh;

    .app__content {
        position: absolute;
        background-color: #f5f5f5;
        min-height: calc(100vh - var(--vh-browser-difference));
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        /* IE exclusive */
        @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-height: 100vh;
        }
    }
`;

export default App;
