import styled from "styled-components";
import icons from "Assets/unity-1.6.0/images/em-icons.svg";

type Props = {
    name: string;
    optionalClass?: string;
    color?: string;
    onClickCallback?: Function;
}

const Icon = ({ name, optionalClass, color, onClickCallback }: Props) => (
    <IconStyle
        color={color}
        className={`${optionalClass} em-c-icon em-c-icon--small`}
        onClick={() => (onClickCallback ? onClickCallback() : undefined)}
    >
        <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref={`${icons}#${name}`}
        ></use>
    </IconStyle>
);

interface StyleProps {
    color?: string;
}

const IconStyle = styled.svg<StyleProps>`
    fill: ${(props) => props.color};
`;

export default Icon;
