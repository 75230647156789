
import { default as React, useCallback, useEffect, useState } from 'react'
import { Store } from 'redux'
import { MsalProvider } from './MsalProvider'
import { AuthenticationState } from './enums'

export interface IAzureADProps {
    provider: MsalProvider
    reduxStore?: Store
    forceLogin?: boolean
  }

export const AzureAuthentication:React.FunctionComponent<any> = props => {
    const { provider, forceLogin } = props
    const [authenticationState, _setAuthenticationState] = useState(provider.authenticationState)

    useEffect(() => {
        provider.registerAuthenticationStateHandler(setAuthenticationState)

        if (props.reduxStore) {
            provider.registerReduxStore(props.reduxStore)
        }
        
        if (forceLogin) {
            login()
        }

        return () => {
            provider.unregisterAuthenticationStateHandler(setAuthenticationState)
        }
    }, [forceLogin])
    
    
    const setAuthenticationState = useCallback(
        (newState: AuthenticationState) => {
            if (newState !== authenticationState) {
                _setAuthenticationState(newState)
            }
        },
        [authenticationState, forceLogin],
    )

    const login = useCallback(() => {
        provider.login()
    }, [])

    switch (authenticationState) {
        case AuthenticationState.Authenticated:
            return props.children
        case AuthenticationState.Unauthenticated:
            return null
        case AuthenticationState.InProgress:
            return null
        default:
            return null
      }
}
