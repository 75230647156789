import styled from 'styled-components';
import backgroundImg from "Assets/unity-1.6.0/images/welcome-background.png";
import { COLOR } from 'Utilities/constants';

export const FooterStyled = styled.div`
    z-index: 9;
`;

export const FooterContainer = styled.div`
    color: #f5f5f5;
    z-index: 10;
    width: 100%;
    background-size: 200%;
    background-image: url(${backgroundImg});
    background-position: left;
    margin-top: 0;
`;

export const FooterInner = styled.div`
    padding: 5rem 0;
    @media (max-width: 84rem) {
        padding: 2rem;
    }
`;

export const CustomButton = styled.a`
    background: none;
    color: ${COLOR.WHITE};
    margin-bottom: 1rem;
    width: 15rem;
    &:hover {
        font-family: EMprintSemiBold;
        background: #ffffff;
    }
    @media (max-width: 22rem) {
        width: 12rem;
    }
`;

export const CustomLink = styled.a`
    font-size: 1rem;
    color: ${COLOR.WHITE};
    font-family: EMPrintRegular;
    text-decoration: underline;
`;

export const SubFooter = styled.div`
    background-color: ${COLOR.DEEP_BLUE};
`;

export const SubFooterInner = styled.div`
    padding: 0 0;
    @media (max-width: 84rem) {
        padding: 0 1.3rem;
    }
`;

export const NavLink = styled.a`
    font-size: 1rem;
    letter-spacing: 0px;
    padding: 1rem 0 1rem 2rem;
    color: ${COLOR.WHITE};
    opacity: 1;
    width: fit-content;
    font-family: 'EMprintSemiBold';
    @media (max-width: 36rem) {
        padding: 1rem 1rem 1rem 0.5rem;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ContainerItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .em-l-grid__item{
        justify-content:center;
    }
    @media (max-width: 36rem) {
        display: flex;
        justify-content: space-between;

        .em-l-grid__item{
            justify-content:center;
        }
    }    
`;

export const Email = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 40rem) {
        justify-content: flex-start;
        word-break: break-word;
    }
`;

export const QuestionHeader = styled.div`
    text-align: right;
    @media (max-width: 40rem) {
        text-align: left;
    }
`;

