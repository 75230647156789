import Icon from '../Icon';
import {
    FooterStyled,
    FooterContainer,
    FooterInner,
    CustomLink,
    LinkContainer,
    SubFooter,
    SubFooterInner,
    NavLink,
    ContainerItem,
    CustomButton,
    Email,
    QuestionHeader
} from './FooterStyles';

const Footer = () => (
    <FooterStyled>
        <FooterContainer>
            <div className="em-l-container">
                <FooterInner>
                    <div className="em-l-grid em-l-grid--2up" >
                        <div className="em-l-grid__item">
                            <CustomButton href="https://goto/devportal"
                                target="_blank"
                                rel="noreferrer"
                                className="em-u-clickable em-c-btn em-u-font-size-med-2">
                                Developer Portal
                            </CustomButton>
                            <div className="em-u-font-style-regular">Built by developers for developers</div>
                        </div>
                        <div className="em-l-grid__item">
                            <QuestionHeader className="em-u-font-size-med-2 em-u-padding-top em-u-padding-bottom">Question or suggestion?</QuestionHeader>
                            <Email>
                                <Icon name="envelope-closed" optionalClass="em-c-icon--medium"/>
                                <CustomLink
                                    className="em-u-text-align-right em-u-padding-left-half"
                                    href="mailto:opensource@exxonmobil.com">
                                    opensource@exxonmobil.com
                                </CustomLink>
                            </Email>
                        </div>
                    </div>
                </FooterInner>
            </div>
        </FooterContainer>
        <SubFooter>
            <div className="em-l-container">
                <SubFooterInner>
                    <ContainerItem>
                        <div className="em-l-grid__item">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="110px" height="30px" viewBox="5 0 110 30" enableBackground="new 0 0 110 30" xmlSpace="preserve">
                                <path fill="white" d="M44.7,9.5h2.6v0.9c0.3-0.2,1.2-1.1,2.9-1.1c2,0,3.6,1.5,3.6,3.8v6H51v-5.2c0-1.3-0.6-2-1.8-2
                                            c-1.1,0-1.8,0.7-1.8,2V19h-2.8V9.5z M73.9,14.2c0-1.3,1-2.4,2.4-2.4c1.4,0,2.4,1.1,2.4,2.4c0,1.3-1,2.4-2.4,2.4
                                            C74.9,16.6,73.9,15.6,73.9,14.2z M71.2,14.2c0,2.7,2.2,5,5.1,5c2.9,0,5.1-2.2,5.1-5c0-2.7-2.2-5-5.1-5C73.4,9.3,71.2,11.5,71.2,14.2
                                            z M35.7,14.2c0-1.3,1-2.4,2.4-2.4c1.4,0,2.4,1.1,2.4,2.4c0,1.3-1,2.4-2.4,2.4C36.7,16.6,35.7,15.6,35.7,14.2z M32.9,14.2
                                            c0,2.7,2.2,5,5.1,5c2.9,0,5.1-2.2,5.1-5c0-2.7-2.2-5-5.1-5C35.1,9.3,32.9,11.5,32.9,14.2z M94.9,9.5h2.8v8.9V19h-2.8V9.5z M94.9,6
                                            h2.8v2.4h-2.8V6z M99.9,6h2.8v12.4V19h-2.8V6z M90.4,14.2c0,1.3-1,2.4-2.4,2.4c-1.4,0-2.4-1-2.4-2.4c0-1.4,1-2.4,2.4-2.4
                                            C89.4,11.9,90.4,12.9,90.4,14.2z M85.7,19l0-0.7c0.2,0.1,1.1,1,2.9,1c2.4,0,4.6-2.2,4.6-5c0-2.8-2.1-5-4.6-5c-1.9,0-2.8,0.9-2.9,1V6
                                            H83v13H85.7z M19.8,19l2.9-3.8l1.8,1.9l-4.2,5.2h3.3l2.7-3.5l5,5.1h4l-7.3-7.3l5.7-7.2h-3.3l-4.2,5.4l-1.8-1.8l4.7-5.9h-3.3
                                            l-3.2,4.1l-1.7-1.8H17l3.9,4L16.5,19H19.8z M7.3,6h8.6v2.4h-5.7v2.7h5.4v2.4h-5.4v3.1h5.7V19H7.3V6z M56,6h4.6l2.2,9.5h0.1L65.1,6
                                            h4.5v13h-2.7V9.2h-0.1L64.3,19h-3.1l-2.4-9.8h-0.1V19H56V6z" />
                            </svg>
                        </div>
                        <LinkContainer>
                            <NavLink
                                href="https://ishareteam2.na.xom.com/sites/GSSSSAC/dp/SiteAssets/DP%20notice%20countries.htm"
                                target="_blank"
                                rel="noreferrer"
                                className="em-u-clickable">Privacy Policy
                            </NavLink>
                        </LinkContainer>
                    </ContainerItem>
                </SubFooterInner>
            </div>
        </SubFooter>
    </FooterStyled>
);

export default Footer;