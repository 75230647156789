import React from "react";
import styled from "styled-components";
import SubHeader from "SharedComponents/SubHeader";
import { COLOR } from "Utilities/constants";
import defaultBackground from "Assets/unity-1.6.0/images/welcome-background.png";

interface Props {
    children: React.ReactNode;
    title: string;
    subtitle?: string;
    backgroundColor?: string;
    bgUrl?: string;
}

const ContentView = ({
    children,
    title,
    subtitle,
    backgroundColor,
    bgUrl,
}: Props) => {
    return (
        <ContentViewStyle>
            <SubHeader
                bgUrl={bgUrl ? bgUrl : defaultBackground}
                title={title}
                subtitle={subtitle}
            />
            <Content color={backgroundColor}>{children}</Content>
        </ContentViewStyle>
    );
};

export const ContentViewStyle = styled.div`
    z-index:10;
    width: 100%;   
    @media (max-width: 46.75rem) {
        margin-top: 5.5rem;
    }
`;

const Content = styled.div<{ color }>`
    margin: 0;
    min-height: 40rem;
    background-color: ${(props) => props.color || COLOR.WHITE};
    display: flex;
    flex-direction: column;
`;

export default ContentView;