import React from "react";
import styled from "styled-components";
import Icon from "SharedComponents/Icon";
import { COLOR } from "Utilities/constants";

interface Props {
  onChange: (newValue: string) => void;
  value: string;
  label?: string;
  optionalClass?: string;
  placeholder?: string;
  icon?: string;
  disabled?: boolean;
  width?: number;
}

export const InputField: React.FC<Props> = ({
  label,
  value,
  onChange,
  optionalClass,
  placeholder,
  icon,
  disabled,
  width,
}) => {
  return (
    <InputStyles width={width} className={`${optionalClass}`}>
      {label ? (
        <label className="em-c-field__label em-u-font-style-regular">
          {label}
        </label>
      ) : null}

      <div className="em-c-field__body">
        <input
          type="input"
          id="input"
          className="em-c-input"
          placeholder={`${placeholder}`}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={disabled}
        />
        {icon && (
          <Icon
            name={icon}
            color={COLOR.DEEP_BLUE}
            optionalClass="em-c-field__icon em-icon--medium"
          />
        )}
      </div>
    </InputStyles>
  );
};

interface InputProps {
  width?: number;
}

export const InputStyles = styled.form<InputProps>`
  color: ${COLOR.DEEP_BLUE};
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .em-c-field__body {
    border-radius: 5px;
    background: white;
    width: ${(props) => props.width}%;
  }
  .em-c-input {
    border: 1px solid #cccccc;
  }
`;

export default InputField;
