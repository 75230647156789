import Button from "SharedComponents/Button";
import styled from "styled-components";

interface IProps {
    message: string;
    header: string;
    closeCallback: Function;
}

const PopUpMessage = ({ message, header, closeCallback }: IProps) => {
    return (
        <ModalContainer>
            <div className="em-c-modal__header">
                <h3 className="em-c-modal__title">{header}</h3>
                <Button
                    optionalClass="em-c-btn em-c-btn--bare em-c-modal__close-btn em-js-modal-close-trigger"
                    name="Close"
                    callback={closeCallback}
                />
            </div>
            <div className="em-c-modal__body em-c-text-passage em-c-text-passage--small em-u-padding-top-double em-u-padding-bottom-double">
                {message}
            </div>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
  background-color: white;
  width: 50rem;
  padding: 3rem 3rem;
  .em-c-btn--bare:hover, .em-c-btn--bare:focus {
    background:none;
    }
    .em-c-modal__header {
        border-bottom: 1px solid #e5e5e5;
    }
    @media (max-width: 55rem) {
        width: 38rem;
    }
    @media (max-width: 40rem) {
        width: 20rem;
        padding: 2rem 2rem 1rem;
    }
 `;

export default PopUpMessage;


