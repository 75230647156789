import { MsalProvider } from "./Authentication/MsalProvider";
import { LoginType } from "./Authentication/enums/LoginType";

const config = {
    auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
        authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        tokenRenewalOffsetSeconds: 60 * 10
    }
}

const authenticationParameters = {
    scopes: [`${process.env.REACT_APP_AUTH_SCOPE}`, 'User.ReadBasic.All'],
    extraScopesToConsent: [
        'https://storage.azure.com/user_impersonation'
    ]
}

const authProvider = new MsalProvider(
    config,
    authenticationParameters,
    LoginType.Redirect
);

export { authProvider };