import bgUrl from "Assets/unity-1.6.0/images/document-background.png";
import ContentView from "Layouts/ContentView";
import DocumentCard from "Pages/DocumentsPage/Components/DocumentCard";
import { useLayoutEffect } from "react";
import styled from "styled-components";
import { COLOR } from "Utilities/constants";
import { listdocs } from "Utilities/listdocs";

const DocumentsPage = () => {
  useLayoutEffect(
    () => window.scroll({ top: 0, behavior: "smooth" }), []
  );
  return (
    <DocumentsContainer className="em-l-container">
      <ContentView title="Documents" subtitle="Useful information for developers" bgUrl={bgUrl}>
        <ContentContainer>
          <div className="em-l-grid em-l-grid--2up">
            {listdocs.map((document) => (
              <DocumentCard key={document.id} {...document} />
            ))}
          </div>
        </ContentContainer>
      </ContentView>
    </DocumentsContainer>
  )
};

const ContentContainer = styled.div`
    background: ${COLOR.GRAY};
    padding: 5rem 7.5rem 6rem 8.5rem;
    font-family: EMPrintRegular;
    @media (max-width: 84em) {
        padding: 3.5rem 1.5rem;
    }
`;

const DocumentsContainer = styled.div`
    z-index: 10;
    margin-top: 10rem;
    width: 100%;
    @media (max-width: 46.75rem) {
        margin-top: 5.5rem;
    }
    @media (max-width: 36rem) {
        margin-top: 3rem;
    }
`;



export default DocumentsPage;