export const listfaqs = [
    {
        id: 1,
        question: "What is Open Source Office?",
        answer: [
            <p className="em-u-font-style-bold">The Open Source Office was launched with some general objectives:</p>,
            <ul className="em-c-bulleted-list">
                <li className="em-c-bulleted-list__item">Creating guidelines around usage and contribution to open source, aiming to foster OS usage while mitigating exposure to potential copyright infringement claims;</li>
                <li className="em-c-bulleted-list__item">Facilitating the contribution process;</li>
                <li className="em-c-bulleted-list__item">Raising awareness about open source, recommending and delivering trainings;</li>
                <li className="em-c-bulleted-list__item">General consulting;</li>
            </ul>]
    },
    {
        id: 2,
        question: "Am I allowed to contribute to open source projects? Or publish my own project?",
        answer: [
            'Publishing code externally- either contributing to existing projects or publishing your own project is considered a Release of Company Information, and therefore must undergo an approval process before it can be done. Click ',
            <a className="em-c-text-link" href="https://mysite.na.xom.com/personal/SA_FAABREU/Shared%20Documents/External%20Contribution%20Process%20overview.docx">here</a>,
            " for an overview of the contribution process and what's required, and ",
            <a className="em-c-text-link" href="https://home.ds.xom.cloud/opensourceoffice/opensourceofficeform">here</a>,
            " to submit your contribution request."]
    },
    {
        id: 3,
        question: "How long does the contribution request process take?",
        answer: ["A couple of days to several weeks, depending on a few factors: the complexity of your contribution, where you're located and what organization you belong to. There is a Blanket Law Endorsement for low complexity contributions authored by EMIT associates in the US, which will speed up the review process for these type of contributions. We're working on obtaining similar endorsements for other countries and Organizations - but until then, non-US/EMIT contributions will need to undergo Law review by Organization and in-country Law  contacts."]
    },
    {
        id: 4,
        question: "I want to use an open source app, how do I go about it?",
        answer: [
            "Depends on what you mean. The process for obtaining software is the same for OS applications as for commercial software - use the ITServices software request forms. For open source software meant to be used a component within you application (such as libraries, standalone code, and some distributions), follow the ",
            <a className="em-c-text-link" href="https://ishareteam2.na.xom.com/sites/ITRM/ic/ssgroup/Shared%20Documents1/Sonatype%20Nexus/Guidelines%20for%20Open%20Source%20Software.docx">guidelines for Open Source Components</a>,
            " from ",
            <a className="em-c-text-link" href="https://portal.ssg.xom.cloud/home">Software Security Group.</a>
        ]
    },
    {
        id: 5,
        question: "Can I use components licensed under any OS license?",
        answer: [
            "The DOAG requires that all non-standard agreements be reviewed and endorsed by Law, and OSS licenses are considered to be non-standard agreements. Law has issued several Blanket Law Endorsements (BLEs), which cover multiple OS licenses. If all the conditions in the BLE are met, a component under that license is approved; if not, a separate Law review is needed. You can review the BLEs and other applicable documents ",
            <a className="em-c-text-link" href="https://ishareteam1.na.xom.com/sites/ITLaw/BLE%20Library/Forms/AllItems.aspx">here.</a>
        ]
    },
];