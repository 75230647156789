import styled from "styled-components";

const LoadingIcon = () => {
    return (
        <Spinner>
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </Spinner>
    )
}

const Spinner = styled.div`
    text-align: center;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    justify-content: flex-end;
    padding: 1rem;
    z-index: 6;
    .bounce1, .bounce2, .bounce3{
        background-color: gray;
    }
    div {
        width: 12px;
        height: 12px;
        background-color: white;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
    }
    @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
            -webkit-transform: scale(0);
            transform: scale(0);
        } 40% { 
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
        }
    }
`;

export default LoadingIcon;