import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "svgxuse";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import store from "./Store/store";
import App from "./Pages";
import * as serviceWorker from "./serviceWorker";

import { AzureAuthentication } from "./Services/Authentication/AzureAuthentication";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { authProvider } from "Services/auth";

import "./Assets/unity-1.6.0/css/em-unity-1.6.0.css";
import "./index.css";

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APP_INSIGHT,
        disableFetchTracking: false
    }
});

ReactDOM.render(
    <Provider store={store}>
        <AzureAuthentication provider={authProvider} forceLogin>
            <App />
        </AzureAuthentication>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
