import styled from "styled-components";
import backgroundImg from "Assets/unity-1.6.0/images/code-contribution.png";
import CodeContributionForm from "Pages/Homepage/Sections/CodeContributionSection/Components/CodeContributionForm";
import { useState } from "react";

const CodeContributionSection = () => {
    const [isContributionFormShowing, setIsContributionFormShowing] = useState(false);

    return (
        <SectionBackGround >
            <Container>
                {isContributionFormShowing && (
                    <CodeContributionForm closeCallback={() => setIsContributionFormShowing(false)} />)
                }
                <h2>Would you like to contribute?</h2>
                <CustomButton className="em-c-btn" onClick={() => setIsContributionFormShowing(true)}>Code contribution request</CustomButton>
            </Container>
        </SectionBackGround>
    );
};

const SectionBackGround = styled.div`
    color: #f5f5f5;
    min-height: 33rem;
    z-index: 10000 !important;
    background-size: cover;
    background-image: url(${backgroundImg});
    background-position: right;
    background-repeat: no-repeat;
    margin-top: 0;
    @media (max-width: 84em) {
        padding: 3rem;
    }
    @media (max-width: 40em) {
        padding: 2rem;
    }
`;

const Container = styled.div`
    position: relative;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 30rem;
    padding: 3rem 8rem 3.5rem;
    text-align: center;
    @media (max-width: 40em) {
        padding: 2.5rem 2rem 2.5rem;
    }
`;

const CustomButton = styled.button`
    background: none;
    color: #ffffff;
    font-size: 1.5rem;
    padding: 2.4rem 2.4rem;
    margin-top: 3rem;
    &:hover {
        font-family: EMprintSemiBold;
        background: #ffffff;
    }
    @media (max-width: 40em) {
        width: 100%;
        font-size: 20px;
    }
    @media (max-width: 20em) {
        width: 100%;
        font-size: 1rem;
        padding: 0.5rem 0.5rem;
    }
`;

export default CodeContributionSection;