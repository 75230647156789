import { authProvider } from "Services/auth";

export interface IUser {
    businessPhones: any[],
    displayName: string,
    givenName: string,
    jobTitle: string,
    mail: string,
    mobilePhone: string,
    officeLocation: string,
    preferredLanguage: string,
    surname: string,
    userPrincipalName: string,
    id: string
}

export const getUsers = async (searchQuery: string): Promise<IUser[]> => {
    const tokenResponse = await authProvider.getAccessToken();
    return fetch(`https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(mail,'${searchQuery}') or startswith(surname,'${searchQuery}')&$top=5`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${tokenResponse.accessToken}`,
            },
        }
    ).then((response) =>
        response.json(),
    ).then(data => data.value.map((item: any) => ({
        displayName: item.displayName,
        mail: item.mail,
        id: item.id
    })));
}
