import { useState } from "react";
import Icon from "SharedComponents/Icon";
import styled from "styled-components";
import { COLOR } from "Utilities/constants";

interface Props {
    question: string,
    answer: any;
};

const ExpandableSection = ({ question, answer }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <section className={isOpen ? 'em-c-section em-c-section--expandable' : 'em-c-section em-c-section--expandable em-is-closed'}>
            <QuestionHeader onClick={() => setIsOpen(!isOpen)} className="em-c-section__header em-js-section-trigger">
                <Icon name="caret-down" optionalClass="em-c-icon--medium em-c-section__icon" />
                <h2 className="em-c-section__title ">
                    {question}
                </h2>
            </QuestionHeader>
            <Answer className="em-c-section__body em-js-section-target">
                <div className="em-u-padding-bottom-double em-u-padding-left-double">
                    {answer.map((ans,index)=>
                       <span key={index}>{ans}</span>
                    )}
                </div>
            </Answer>
            <Separator />
        </section>
    )
};

const Answer = styled.div`
    span {
        font-size: 1.125rem;
        font-family: EMPrintRegular;
        white-space: pre-wrap;
        line-height: 1.6em;
    }
`;

const QuestionHeader = styled.header`
    border-bottom: none;
    padding-bottom: 1.5rem;
    margin-bottom: 0.5rem;
    svg {
        min-width: 1.5rem;
        min-height: 1.5rem;
    };
    @media (max-width: 40em) {
        h2 {
            font-size: 1.125rem;
        }
    }
`;

const Separator = styled.hr`
    background: ${COLOR.DEEP_BLUE};
    margin: 0;
`;

export default ExpandableSection;