import bgUrl from "Assets/unity-1.6.0/images/document-background.png";
import icons from "Assets/unity-1.6.0/images/em-icons.svg";
import ContentView from "Layouts/ContentView";
import { useEffect, useLayoutEffect, useState } from "react";
import Icon from "SharedComponents/Icon";
import styled from "styled-components";
import { COLOR } from "Utilities/constants";
import { getLawFiles } from "../../Services/StorageService/getLawFiles";

const LawLibraryPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sourceFiles, setSourceFiles] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);

  useLayoutEffect(
    () => window.scroll({ top: 0, behavior: "smooth" }), []
  );

  useEffect(() => {
    getLawFiles().then(rawFiles => {
      rawFiles.sort((x, y) => x.category > y.category ? 1 : -1);
      rawFiles.forEach(x => x.files.sort((x, y) => x.title > y.title ? 1 : -1));
      //Trim off the '1. ' prefixed to first category (used for ordering when sorted)
      rawFiles[0].category = rawFiles[0].category.substring(2);
      setSourceFiles(rawFiles);
      setFiles(rawFiles);
    })
  }, []);

  return (
    <FileListContainer className="em-l-container">
      <ContentView title="Law Library" subtitle="List of Open Source licenses endorsed for use by EMGSC" bgUrl={bgUrl}>
        <ContentContainer>
          <h3>Search</h3>
          <div className="em-c-search__body">
            <input type="search" name="search-box" id="search-box" className="em-c-search__input" placeholder="Type to search" value={searchTerm} onChange={(e) => {
              setSearchTerm(e.target.value);
              setFiles(search(sourceFiles, e.target.value));
            }} />
            <button className="em-c-btn em-c-btn--primary" aria-label="Search" onClick={() => {
              if (searchTerm) {
                setSearchTerm('');
                setFiles(search(sourceFiles, ''));
              }
            }}>
              <div className="em-c-btn__inner">
                <svg className="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="../../Assets/unity-1.6.0/images//em-icons.svg">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`${icons}#${getSearchIcon(searchTerm)}`}></use>
                </svg>
              </div>
            </button>
          </div>
          <ul className="em-c-link-list" style={{ margin: "1.5rem 1rem" }}>
            {files.map((filegroup) => (
              <div style={{ margin: "2rem 0rem" }} key={filegroup.category}>
                <h4>{filegroup.category}</h4>
                {filegroup.files.map((fileinfo) => (
                  <li className="em-c-link-list__item" key={fileinfo.title}>
                    <FileLink href={fileinfo.link} target="_blank" className="em-c-link-list__link">
                      <Icon name={getIcon(fileinfo.link)} color={COLOR.WHITE}></Icon>
                      <span style={{ margin: "0 0 0 0.5rem" }}>{fileinfo.title}</span>
                    </FileLink>
                  </li>
                ))}
              </div>
            ))}
          </ul>
        </ContentContainer>
      </ContentView>
    </FileListContainer>
  )
};

function search(sourceFiles, text: string) {
  if (!text) {
    return sourceFiles.filter(() => true);
  } else {
    let filteredFiles = sourceFiles.map(group => {
      return {
        category: group.category,
        files: group.files.filter(file => file.title.toLowerCase().includes(text.toLowerCase()))
      };
    })
    return filteredFiles.filter(group => group.files.length > 0);
  }
}

function getIcon(link: string): string {
  link = link.toLowerCase();
  if (link.endsWith('.pdf')) return 'file-pdf-2';
  if (link.endsWith('.ppt') || link.endsWith('.pptx')) return 'file-pptx';
  if (link.endsWith('.htm') || link.endsWith('.html')) return 'file-code';
  if (link.endsWith('.mht')) return 'file-code';
  if (link.endsWith('.doc') || link.endsWith('.docx')) return 'book';
  return 'file-generic';
}

function getSearchIcon(term: string): string {
  if (term) return 'x-filled';
  return 'search';
}

const FileLink = styled.a`
  &:hover {
    color: ${COLOR.WHITE};
  }
`;

const ContentContainer = styled.div`
    background: ${COLOR.GRAY};
    padding: 5rem 7.5rem 6rem 8.5rem;
    font-family: EMPrintRegular;
    color: ${COLOR.WHITE};
    @media (max-width: 84em) {
        padding: 3.5rem 1.5rem;
    }
`;

const FileListContainer = styled.div`
    z-index: 10;
    margin-top: 10rem;
    width: 100%;
    @media (max-width: 46.75rem) {
        margin-top: 5.5rem;
    }
    @media (max-width: 36rem) {
        margin-top: 3rem;
    }
`;



export default LawLibraryPage;