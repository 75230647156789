import React from "react";
import {
    SectionContainer,
    SectionTitle,
    SectionSubTitle,
} from "./SectionStyles";
import LoadingIndicator from "SharedComponents/LoadingIndicator";

interface Props {
    children: React.ReactNode;
    sectionId?: string;
    title: string;
    subtitle?: string;
    backgroundColor?: string;
    titleColor?: string;
    loading?: boolean;
}

const Section = ({
    children,
    sectionId,
    title,
    subtitle,
    backgroundColor,
    titleColor,
    loading,
}: Props) => {
    return (
        <SectionContainer color={backgroundColor} id={sectionId}>
            <SectionTitle
                className="em-u-font-style-semibold"
                color={titleColor}
            >
                {title}
                {subtitle && ' | '}
                {subtitle && <SectionSubTitle>{subtitle}</SectionSubTitle>}
            </SectionTitle>
            {loading ? <LoadingIndicator /> : <>{children}</>}
        </SectionContainer>
    );
};

export default Section;
