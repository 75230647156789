import styled from "styled-components";
import DocumentsSection from "./Sections/DocumentsSection";
import FaqSection from "./Sections/FaqSection";
import TeamSection from "./Sections/TeamSection";
import WelcomeSection from "./Sections/WelcomeSection";
import CodeContributionSection from "./Sections/CodeContributionSection";

const Homepage = () => (
    <HomepageContainer>
        <HomepageContent className="em-l-container">
            <WelcomeSection />
            <DocumentsSection />
            <FaqSection />
            <CodeContributionSection />
            <TeamSection />
        </HomepageContent>
    </HomepageContainer>
);

const HomepageContainer = styled.div`
    margin-top: 8rem;
    z-index: 10;
    @media (max-width: 46.75rem) {
        margin-top: 5.5rem;
    }
`;

const HomepageContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export default Homepage;