

import Section from "Layouts/Section";
import { COLOR } from "Utilities/constants";
import { SeeAllButton } from "Layouts/Section/SectionStyles";
import { Link } from "react-router-dom";
import DocumentCard from "Pages/DocumentsPage/Components/DocumentCard";
import { listdocs } from "Utilities/listdocs";

const DocumentsSection = () => {
    return (
        <Section
            backgroundColor={COLOR.GRAY}
            sectionId="documents"
            title="Documents"
            subtitle="Useful information for developers"
        >
            <div className="em-l-grid em-l-grid--2up">
                {listdocs.slice(0, 2).map((document) => (
                    <DocumentCard key={document.id} {...document} />
                ))}
            </div>
            <SeeAllButton>
                <Link
                    to="/documents"
                    className="em-c-btn em-u-font-style-semibold"
                >
                    See all
                </Link>
            </SeeAllButton>
        </Section>
    );
};

export default DocumentsSection;
